import React, { Component } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Наши услуги</h2>
            <p>
              Свяжитесь с нами и компания Леара с радостью окажет вам профессиональную помошь
            </p>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <FontAwesomeIcon icon={d.icon} className="services-icon"/>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
