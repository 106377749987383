import React, { Component } from 'react'

export class AbilitiesLeft extends Component {
  render() {
    return (
        <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="about-text">
                <h2>{this.props.data ? this.props.data.title : 'loading...'}</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <h3>{this.props.data ? this.props.data.whyTitle : 'loading...'}</h3>
                <div className="row">
                {this.props.data && this.props.data.Why &&
                  <div className={`${this.props.data.Why2 ? "col-xl-6 col-md-6 col-12" : "col-sm-12"}`}>
                    <ul>
                      {this.props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>)}
                    </ul>
                  </div>
  }
  {this.props.data && this.props.data.Why2 &&
                  <div className="col-xl-6 col-md-6 col-12">
                    <ul>
                    {this.props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>)}

                    </ul>
                  </div>
  }
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6"> <img src={this.props.data ? this.props.data.image : 'loading...'} className="img-fluid" alt=""/> </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AbilitiesLeft
