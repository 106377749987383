import React, { Component } from "react";
import {
  Link
} from "react-router-dom";

export class Products extends Component {
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Наши тепловизоры</h2>
            <p>
              Тепловизионные системы доступные в продаже
            </p>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      {d.Why &&
                        <div className="col-sm-12 text-left">
                          <ul>
                            {d.Why.map((d, i) => <li key={`${d}-${i}`}>{d}</li>)}
                          </ul>
                        </div>
                      }
                      {d.link &&
                        <a target='_blank' href={d.link ? d.link : "Loading"} className="btn btn-custom btn-lg page-scroll">Узнать больше</a>
                      }
                    </div>
                  </div>
                </div>
              ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Products;
