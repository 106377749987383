import React, { Component } from 'react'
import ImageBanner from '../components/ImageBanner';
import AbilitiesRight from '../components/abilities-right';
import AbilitiesLeft from '../components/abilities-left';
import Services from '../components/services';

export class FenceDetails extends Component {
  render() {
    if (this.props.data) {
      return (
        <div>
          <ImageBanner data={this.props.data.FenceDetailsHeader} />
          <AbilitiesRight data={this.props.data.About} />
          <AbilitiesLeft data={this.props.data.AboutThermalSoftware} />
          <AbilitiesRight data={this.props.data.AboutThermalRemote} />
          <Services data={this.props.data.Services} />
        </div>
      )
    } else {
      return null;
    }
  }
}

export default FenceDetails
