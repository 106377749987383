import React, { Component } from 'react'
import ImageBanner from '../components/ImageBanner';
import AbilitiesRight from '../components/abilities-right';
import AbilitiesLeft from '../components/abilities-left';
import VideoBaner from '../components/video-banner/VideoBanner';

export class GreenEnergyDetails extends Component {
  render() {
    if (this.props.data) {
      return (
        <div>
          <ImageBanner data={this.props.data.GreenEnergyImageBanner} />
          {/* <VideoBaner data={this.props.data.GreenEnergyVideoBanner} /> */}
          <AbilitiesLeft data={this.props.data.AboutSolarEnergy} />
          <AbilitiesRight data={this.props.data.AboutWindEnergy} />
        </div>
      )
    } else {
      return null;
    }
  }
}

export default GreenEnergyDetails
