import React, { Component } from "react";
import {
  Link
} from "react-router-dom";
import classes from './VideoBanner.module.css';

export class VideoBaner extends Component {
  render() {
    return (
      <div className={classes.Container} >
      <video autoPlay="autoplay" loop="loop" muted playsInline className={classes.Video} poster={this.props.data ? this.props.data.poster : "Loading"}>
        <source src={this.props.data ? this.props.data.video : "Loading"} type="video/mp4" />
              Your browser does not support the video tag.
          </video>

      <div className={classes.Content}>
        <div className={classes.SubContent} >
          <h1>
            {this.props.data ? this.props.data.title : "Loading"}
            <span></span>
          </h1>
          <p>
            {this.props.data ? this.props.data.paragraph : "Loading"}
          </p>
          {(this.props.data && this.props.data.link) &&
            <Link to={this.props.data ? this.props.data.link : "Loading"} className="btn btn-custom btn-lg page-scroll">Узнать больше</Link>
          }
          {" "}
        </div>
      </div>
    </div>
    );
  }
}

export default VideoBaner;
