import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>{this.props.data ? this.props.data.title : 'Loading'}</h2>
            <p>
              {this.props.data ? this.props.data.description : 'Loading'}
            </p>
          </div>

          <div className="portfolio-items row">
            {this.props.data
              ? this.props.data.licenses.map((d, i) => (
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <a
                        href={d.image}
                        title={d.title}
                        data-lightbox-gallery="gallery1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="hover-text">
                          <h4>{d.title}</h4>
                        </div>
                        <img
                          src={d.image}
                          className="img-fluid"
                          alt={d.title}
                        />{" "}
                      </a>{" "}
                    </div>
                  </div>
                </div>

              ))
              : "Loading..."}
          </div>

        </div>
      </div>
    );
  }
}

export default Gallery;
