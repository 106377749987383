import React, { Component } from 'react'
import ScrollToTop from './components/ScrollToTop'
import Navigation from './components/navigation';
import ImageBanner from './components/ImageBanner';
import VideoBaner from './components/video-banner/VideoBanner';
import Features from './components/features';
import AbilitiesRight from './components/abilities-right';
import AbilitiesLeft from './components/abilities-left';
import Services from './components/services';
import Gallery from './components/gallery';
import Testimonials from './components/testimonials';
import Team from './components/Team';
import Contact from './components/contact';
import Footer from './components/footer';
import JsonData from './data/data.json';
import ThermalDetails from './pages/ThermalDetails';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import GreenEnergyDetails from './pages/GreenEnergyDetails';
import FenceDetails from './pages/FenceDetails';

export class App extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({ landingPageData: JsonData })
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <div>
            <Navigation />
            <Switch>
              <Route path="/energy">
                <GreenEnergyDetails data={this.state.landingPageData.GreenEnergyDetails} />
              </Route>
              <Route path="/thermal">
                <ThermalDetails data={this.state.landingPageData.ThermalDetails} />
              </Route>
              <Route path="/fence">
                <FenceDetails data={this.state.landingPageData.FenceDetails} />
              </Route>
              <Route path="/">
                <ImageBanner data={this.state.landingPageData.Thermal} />
                <ImageBanner data={this.state.landingPageData.GreenEnergy} />
                <ImageBanner data={this.state.landingPageData.Fence} />
                <Contact data={this.state.landingPageData.Contact} />
              </Route>
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}

export default App;
