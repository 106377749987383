import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">

                <div className="section-title">
                  <h2>Наш офис и склад</h2>
                  <p>
                    ПЧУП "ЛЕАРА"
                  </p>
                </div>

                <div>
                  <YMaps>
                    <Map defaultState={{ center: [53.925058, 27.564115], zoom: 15 }} options={{ autoFitToViewport: 'always' }} width="100%" height="400">
                      <Placemark geometry={[53.925058, 27.564115]} />
                    </Map>
                  </YMaps>
                </div>
              </div>
              <div className="col-sm-3 offset-md-1 contact-info">
                <div className="contact-item">
                  <h3>Контакты</h3>
                  <p>
                    <span>
                      <i className="fa fa-phone"></i> Телефон
                  </span>{" "}
                    {this.props.data ? this.props.data.phone : "loading"}
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                    {this.props.data ? this.props.data.email : "loading"}
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-map-marker"></i> Адрес
                  </span>
                    {this.props.data ? this.props.data.address : "loading"}
                  </p>
                </div>
                <div className="contact-item">
                  <p>
                    <span>
                      <FontAwesomeIcon icon={faWallet} /> Реквизиты
                  </span>
                    {this.props.data ? this.props.data.bill : "loading"}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
