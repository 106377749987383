import React, { Component } from "react";
import {
  Link
} from "react-router-dom";

export class ImageBanner extends Component {
  render() {
    return (
      <header id="header">
        <div className="intro" style={{
          backgroundImage: `url(${this.props.data ? this.props.data.image : "Loading"})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
          
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-md-2 intro-text">
                  <h1>
                    {this.props.data ? this.props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>
                  {(this.props.data && this.props.data.link) &&
                    <Link to={this.props.data ? this.props.data.link : "Loading"} className="btn btn-custom btn-lg page-scroll">Узнать больше</Link>
                  }
                  {" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default ImageBanner;
