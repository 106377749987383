import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Navigation extends Component {
  render() {
    return (
      <div>
        <nav id="menu" className="navbar navbar-default navbar-light fixed-top navbar-expand-md navbar-no-bg">
          <div className="container">
            <Link className="navbar-brand page-scroll" to="/">
              <img className="logo-img" src="/img/logoQ.png" alt="Leara" />
            </Link>{" "}
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-controls="bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="navbar-nav ml-auto">
                <li>
                  <Link className="dropdown-item page-scroll" to="/">
                    Главная
                </Link>
                </li>
                <li>
                  <a href="/#contact" className="dropdown-item page-scroll">
                    Контакты
                </a>
                </li>
                <li>
                  <a href="tel:+375173797647" className="dropdown-item page-scroll">
                    +375 17 379 76 47
                </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div style={{ marginBottom: 70 }}></div>
      </div>
    );
  }
}

export default Navigation;
