import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; {new Date().getFullYear()} ПЧУП "ЛЕАРА". Все права защищены.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
